/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {


  /**
   * Align the dropdown menu to the left or right based on the available space
   */
  function set_ddm_aligment () {
    $('a.nav-item-tn').each(function () {

      var $this = $(this),
        parent = $this.parent('div.dropdown-menu');

      //Set the alignment based on the items left or right offset.
      var ddm_w = parent.width(),
        os_l = parent.offset().left,
        os_r = ($(window).width() - os_l);

      if (os_r < ddm_w) {
        parent.addClass('align-ddm-right');
      }

    });
  }//end set_ddm_aligment


  function set_blog_item_title_height () {

    setTimeout(function () {

      var items_row = $('div.blog-items-row'),
        blog_archive_item = $('div.blog-archive-item');

      //Reset
      blog_archive_item.find('h3').css('min-height', '0px');
      items_row.each(function(){
        var $this = $(this),
        h3_h = 0,
        h3 = $this.find('div.blog-archive-item h3');

        h3.each(function(){
          var $this_h3 = $(this),
            this_h = $this_h3.height();

          if (h3_h < parseInt(this_h)) {
            h3_h = parseInt(this_h);
          }
        });

        h3.css('min-height', h3_h + 'px');

      });

    }, 350);

  }//end set_blog_item_title_height


  /**
   * Set the image max-height for the content-img blocks
   */
  function set_images_height () {

    var sections = $('section.dynamic-img-size');
    if (sections.length) {

      sections.each(function(){

        var $this = $(this);

        //get the content height, plus 60px top and bottom for spacing:
        var content_h = parseInt($this.find('.content-wrap').outerHeight())/* + 120*/;

        //Set the image height
        $this.find('.img-wrap img').css('max-height', content_h+'px').addClass('loaded');

      });//end sections each

    }//end if len

  }//end set_images_height



  function set_addtional_images_height () {

    var sections = $('section.dynamic-img-size, section.section-layout-halves');
    if (sections.length) {
      sections.each(function(){

        var $this = $(this);

        //Find the parent image to use for setting the child image heights
        var parent_img = $this.find('.add-imgs-parent');

        //Find the addtional images:
        var add_imgs = $this.find('.content-img-block-additional-img');

        if (parent_img.length && add_imgs.length) {
          add_imgs.css('height', parent_img.height()+'px').addClass('loaded');
        }

      });//end sections each
    }

  }//end set_addtional_images_height


  function set_mobile_header_book_btn () {

    if ($('html').hasClass('touchevents') && parseInt($(window).height()) > parseInt($(window).width())) {
      var holder = $('ul.mobile-booking-btn-wrap li.nav-btn-book');
      if (holder.length) {
        var btn = $('div.slide-out-nav li.nav-btn-book');
        holder.html(btn.html());
      }
    } else {
      if ($('ul.mobile-booking-btn-wrap li.nav-btn-book').length) {
        $('ul.mobile-booking-btn-wrap li.nav-btn-book').html('');
      }
    }
  }


  function set_book_ddm_width () {
    var book_menu_item = $('ul[id^=menu-main-menu] li.nav-btn-book');
    var w = book_menu_item.children('a.dropdown-toggle').outerWidth();
    book_menu_item.children('div.dropdown-menu').width(w);
  }


  function subscribe_form_submit(form_vals, form) {

    var loading = $('div.submit-wrap img.loading'),
      feedback_success = $('div.form-messages p.success'),
      feedback_failed = $('div.form-messages p.failed');

    $.ajax({
      type: 'post',
      url: ae_subscribe.ajax_url,
      data: form_vals,
      beforeSend: function (response) {
        loading.addClass('active');
      },
      complete: function (response) {
        loading.removeClass('active');

        //Empty form fields:
        form.find('input[type="email"]').val('');
        form.find('input[type="text"]').val('');
      },
      success: function (response) {

        if (response.response === 'failed') {
          feedback_failed.addClass('active');
        } else {
          feedback_success.addClass('active');
        }

        setTimeout(function () {
          feedback_failed.removeClass('active');
          feedback_success.removeClass('active');
        }, 4000);

      },
    });
  }


  function contact_form_submit(form_vals, form) {

    var loading = form.find('div.submit-wrap img.loading'),
      feedback_success = form.next('div.form-messages').find('p.success'),
      feedback_failed = form.next('div.form-messages').find('p.failed');

    $.ajax({
      type: 'post',
      url: ae_contact.ajax_url,
      data: form_vals,
      beforeSend: function (response) {
        loading.addClass('active');
      },
      complete: function (response) {
        loading.removeClass('active');

        //Empty form fields:
        form.find('input[type="email"]').val('');
        form.find('input[type="text"]').val('');
        form.find('input[type="tel"]').val('');
        form.find('input[type="number"]').val('');
        form.find('textarea').val('');
      },
      success: function (response) {

        if (response.response === 'failed') {
          feedback_failed.addClass('active');
        } else {
          feedback_success.addClass('active');
        }

        setTimeout(function () {
          feedback_failed.removeClass('active');
          feedback_success.removeClass('active');
        }, 4000);

      },
    });
  }


  function set_page_section_link_position () {

    setTimeout(function () {
      var section_links = $('span.page-section-link'),
        header_h = parseInt($('header.header').outerHeight());
      if (section_links.length) {
        section_links.each(function(){
          var $this = $(this);
          $this.css('top', '-'+header_h+'px')
        });
      }
    }, 450);

  }//end set_page_section_link_position


  function position_pinned_press_items () {

    if ($('ul.pinned-items').length) {

      imagesLoaded('.press-pinned-bg', {background: false}, function () {
        setTimeout(function () {
          var pinned_container = $('ul.pinned-items'),
            items = pinned_container.children().length,
            margin = ((items - 1) * parseInt($('ul.pinned-items li:nth-child(2)').css('marginLeft'))),
            max_w = ((parseInt(pinned_container.children().width()) * pinned_container.children().length) + parseInt(margin));

          pinned_container.css('max-width', max_w);
        }, 500);

      });

    }//end if ul.pinned-items len

  }//end position_pinned_press_items


  function change_press_items_order (press_items, order) {

    $('div.filter-no-results').removeClass('active');

    press_items.each(function(){
      var $this = $(this),
          reverse_order = $this.attr('data-reverse-order'),
          original_order = $this.attr('data-original-order');

      if (order === 'new-to-old' || order === 'recommended') {
        //$this.removeClass(reverse_order);
        //$this.addClass(original_order);

        $this.attr('style', original_order)

      } else if (order === 'old-to-new') {
        //$this.removeClass(original_order);
        //$this.addClass(reverse_order);

        $this.attr('style', reverse_order)
      }

    });

  }//end change_press_items_order


  function hide_press_no_results () {
    $('div.filter-no-results').removeClass('active');
  }


  function press_check_for_no_results () {
    var visible = $('div.press-item-outer:not(.d-none):not(.hotel-d-none)');
    if (!visible.length) {
      $('div.filter-no-results').addClass('active');
    }
  }//end press_check_for_no_results


  function show_press_loading() {
    $('body').css('overflow', 'hidden');
    $('div.press-loading').addClass('active');
  }


  function hide_press_loading () {
    $('body').css('overflow', 'initial');
    $('div.press-loading').removeClass('active');
  }


  function revinate_subscribe_form_submit(form_vals, form) {

    var loading = form.find('div.submit-wrap img.loading'),
      feedback_success = form.find('div.form-messages p.success'),
      feedback_failed = form.find('div.form-messages p.failed');

    var form_token = form.data('token');
    if (typeof form_token === 'undefined' || !form_token.length) {
      feedback_failed.addClass('active');

      setTimeout(function () {
        feedback_failed.removeClass('active');
      }, 4000);

      return false;
    }

    var data = {
      tokens: [form_token],
      contacts: [form_vals]
    };

    $.ajax({
      type: 'post',
      url: 'https://contact-api.inguest.com/api/add-contacts-to-lists',
      data: JSON.stringify(data),
      beforeSend: function (response) {
        loading.addClass('active');
      },
      complete: function (response) {
        loading.removeClass('active');

        //Empty form fields:
        form.find('input[type="email"]').val('');
        form.find('input[type="text"]').val('');
      },
      success: function (response) {

        if (typeof response.emailsAddedToLists !== 'undefined' && response.emailsAddedToLists.length) {
          feedback_success.addClass('active');
        } else {
          feedback_failed.addClass('active');
        }

        setTimeout(function () {
          feedback_failed.removeClass('active');
          feedback_success.removeClass('active');
        }, 4000);

      },
    });
  }//end revinate_subscribe_form_submit


  /**
   * Get dates and initialise the date picker for the nav booking form.
   *
   * Set the default date to today and tomorrow.
   */
  function init_date_picker (hotel) {


    jQuery(document).on('click', '.book-now-ddm-outer .dropdown-menu', function (e) {
      e.stopPropagation();
    });


    var min_date = 'today';


    var date_today = new Date();
    date_today.setHours(0, 0, 0, 0);

    //next day
		var default_dep_date_range = 1;

    //Check for the hotel value, if it exists and is Monterosa the set the start date to Dec 1st
    if (hotel.length && hotel === 'monterosa') {

      //Start the first bookable date on Dec 6th.
      var dec6th = new Date(date_today.getFullYear(), 11, 6);
      var todayUTC = Date.UTC(date_today.getFullYear(), date_today.getMonth(), date_today.getDate());
      var dec6thUTC = Date.UTC(dec6th.getFullYear(), dec6th.getMonth(), dec6th.getDate());

      //if the date is greater than Dec 6th then use date_today
      if (todayUTC < dec6thUTC) {

        date_today = dec6th;

        var year = dec6th.getFullYear(),
            month = (dec6th.getMonth() + 1).toString().padStart(2, '0'),
            day = dec6th.getDate().toString().padStart(2, '0');

        min_date = year+'-'+month+'-'+day;

        //Min checkout date of Dec 9th
			  default_dep_date_range = 3;
      }

    }

    var date_tomorrow = new Date(date_today);
    date_tomorrow.setDate(date_tomorrow.getDate() + default_dep_date_range);


    //Load the date display:
    set_booking_date_display(date_today, date_tomorrow);

    //Set the hidden input vals
    set_booking_date_inputs(date_today, date_tomorrow);

    //If the lang is EN, set the locale to the first day of the week - Monday instead of default Sun.
    //Else, use the locale const to set the lang.
    var fp_locale = (ae_locale.ae_locale === 'en') ? {firstDayOfWeek: 1} : ae_locale.ae_locale;

    //Init the date picker
    //flatpickr.l10ns.default.firstDayOfWeek = 1;
    flatpickr("#booking-arrival", {
      minDate: min_date,
      dateFormat: "d M Y",
      mode: "range",
      static: true,
      locale: fp_locale,
      defaultDate: [date_today, date_tomorrow],
      onChange: function (selectedDates, dateStr, instance) {

        if (selectedDates.length === 2) {
          var min_days = 1;

          //If the start date is Dec 1st, make sure min date range is 3 days
					if (hotel.length && hotel === 'monterosa' && selectedDates[0].getFullYear() === 2024 && selectedDates[0].getMonth() === 11 && selectedDates[0].getDate() === 6) {
						min_days = 3;
					}

          var diffTime = Math.abs(selectedDates[1] - selectedDates[0]),
          diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)),
          addDays = Math.max(0, min_days - diffDays);

          if (addDays > 0) {
            selectedDates[1].setDate(selectedDates[1].getDate() + addDays);
            this.setDate(selectedDates);
          }

          //Update the display:
          var arrival_date = new Date(selectedDates[0]);
          var dept_date = new Date(selectedDates[1]);

          set_booking_date_display(arrival_date, dept_date);
          set_booking_date_inputs(arrival_date, dept_date);

        }
      }
    });

  }//end init_date_picker


  /**
   * Set the display dates for the nav booking form.
   *
   * @param date_today
   * @param date_tomorrow
   */
  function set_booking_date_display (date_today, date_tomorrow) {

    var arrival_month = date_today.toLocaleString(ae_locale.ae_locale, { month: 'long' });
    var arrival_day_name = date_today.toLocaleString(ae_locale.ae_locale, { weekday: 'long' });
    arrival_month = arrival_month.charAt(0).toUpperCase() + arrival_month.slice(1);
    var arrival_date_str = '<span class="arrival"><span class="day">'+date_today.getDate()+'</span><span class="month">'+arrival_month+'</span>&nbsp;<span class="year">'+date_today.getFullYear()+'</span><span class="day-name">'+arrival_day_name+'</span></span>';


    var dept_month = date_tomorrow.toLocaleString(ae_locale.ae_locale, { month: 'long' });
    var dept_day_name = date_tomorrow.toLocaleString(ae_locale.ae_locale, { weekday: 'long' });
    dept_month = dept_month.charAt(0).toUpperCase() + dept_month.slice(1);
    var dept_date_str = '<span class="departure"><span class="day">'+date_tomorrow.getDate()+'</span><span class="month">'+dept_month+'</span>&nbsp;<span class="year">'+date_tomorrow.getFullYear()+'</span><span class="day-name">'+dept_day_name+'</span></span>';

    $('#booking-arrival').html(arrival_date_str+dept_date_str);

  }//end set_booking_date_display


  /**
   * Set the nav booking form hidden date inputs
   * @param date_today
   * @param date_tomorrow
   */
  function set_booking_date_inputs (date_today, date_tomorrow) {

    var arrival_input_date = date_today.getFullYear() + '-' + ('0' + (date_today.getMonth()+1)).slice(-2) + '-' + ('0' + date_today.getDate()).slice(-2);
    var dept_input_date = date_tomorrow.getFullYear() + '-' + ('0' + (date_tomorrow.getMonth()+1)).slice(-2) + '-' + ('0' + date_tomorrow.getDate()).slice(-2);

    $('#booking-arrival-date').val(arrival_input_date);
    $('#booking-departure-date').val(dept_input_date);

  }//end set_booking_date_inputs


  /**
   * Number buttons for nav booking form
   */
  function init_quantity_inputs () {

    var minus = $('button.minus'),
        plus = $('button.plus');

    minus.click(function(){

      var $this = $(this),
          input = $this.next('input[type="number"]'),
          number_val = parseInt(input.val()),
          min = 2;

      if (input.attr('id') === 'booking-children') {
        min = 0;
      }

      if (number_val > min) {
        number_val--;
        $this.removeClass('disabled');
      } else {
        $this.addClass('disabled');
      }

      input.val(number_val);
    });


    plus.click(function(){

      var $this = $(this),
          input = $this.prev('input[type="number"]'),
          number_val = parseInt(input.val());

      $('button.minus').removeClass('disabled');

      number_val++;
      input.val(number_val);
    });

  }//end init_quantity_inputs


  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        if (typeof AOS !== 'undefined') {
          AOS.init();
        }

        //Append the nav booking button to the header for mobile portrait devices:
        set_mobile_header_book_btn();


        //Set the desktop book now button dropdown width:
        setTimeout(function () {
          set_book_ddm_width();
        }, 500);


        //Set the nav booking form date picker
        init_date_picker('');


        init_quantity_inputs();


        //Booking form destinaton onChange
        $('select#booking-destinations').change(function(){

          var $this = $(this),
              val = $this.val();
          init_date_picker(val);

        });


        //Handle the nav booking form submit
        $('#aethos-nav-booking-form').submit(function(e){

          e.preventDefault();

          var destination = $('select#booking-destinations').val();
          if (destination === '#') {
            $('select#booking-destinations').focus();
            return false
          }

          //Build up the URL:
          var start_date = $('#booking-arrival-date').val();
          var end_date = $('#booking-departure-date').val();
          var adults = $('input#booking-adults').val();
          var children = $('input#booking-children').val();
          var promo = ($('input#booking-promo-code').val().length) ? '&vc='+encodeURIComponent($('input#booking-promo-code').val()) : '';
          var reservations_url = $('div.nav-booking-form').data('reservations-url')+'?hotel='+destination+'&start_date='+start_date+'&end_date='+end_date+'&adults='+adults+'&children='+children+promo;

          window.location = reservations_url;

          return false;

        });


        //Set images heights for third / 2 third sections and additional images section:
        imagesLoaded('section.dynamic-img-size img', function () {
          set_images_height();
          set_addtional_images_height();
        });

        set_page_section_link_position();
        $(window).resize(function () {
          set_images_height();
          set_addtional_images_height();
          set_mobile_header_book_btn();
          set_page_section_link_position();
          position_pinned_press_items();
        });


        //Enable clicking dropdown nav item
        $('ul li.menu-item-has-children:not(.nav-btn-book)').click(function(){
          location.href = $(this).children('a').attr('href');
        });


        //Main nav click on touch devices
        $('html.touchevents ul li a.dropdown-toggle').click(function (e) {
          var $this = $(this);
          if (!$this.hasClass('link-enabled')) {
            $('html.touchevents ul li a.dropdown-toggle').removeClass('link-enabled');
            $this.addClass('link-enabled');
            e.preventDefault();
            return false;
          }
        });//end landscape touchscreen nav dropdown click

        //Remove main nav click on touch devices on body click
        $('html.touchevents body').click(function (e) {
          $('html.touchevents ul li a.dropdown-toggle').removeClass('link-enabled');
        });

        //Append images to the main nav items and set the DDM alignment:
        if ($('a.nav-item-tn').length) {

          var tn_count = 0;
          $('a.nav-item-tn').each(function () {

            var $this = $(this),
              tn = $this.data('tn');

            if (tn !== undefined) {
              var text = $this.text();
              //Set html:
              var html = '<span class="img-wrap"><img src="' + tn + '" alt="' + text + '"></span><span class="nav-text">' + text + '</span>';
              $this.html(html);

              tn_count++;
            }

          });//end fe nav items

          //Get a count of how many dropdown items with thumbnails and add it as a class to the parent item.
          $('li.dropdown').each(function () {
            var $this = $(this),
                tns_count = $this.find('.dropdown-menu > a .img-wrap').length;

            if (tns_count > 0) {
              $this.addClass('dd-items-'+tns_count);
            }

          });

          set_ddm_aligment();

        }//end set subnav imgs



        //Anchor no link:
        $('a.anchor-no-link').click(function(e){
          e.preventDefault();
          return false;
        });



        //Theme lazyload
        setTimeout(function () {

          if ($('img.theme-lazy-load').length) {
            $('img.theme-lazy-load').each(function(){
              var t = $(this),
                  src = t.data('src');
                t.attr('src', src);
            });
          }

        }, 1250);




        //OnScroll
        var header = $('header.header');
        $(window).on('scroll', function () {
          if ($(this).scrollTop() > header.height()) {
            header.addClass("sticky");
          } else {
            header.removeClass("sticky");
          }
        });//end on Scroll



        //Mobile nav menu click
        $(document).on('click', 'div.hamburger', function () {
          $(this).toggleClass('is-active');
          $('div.navbar-collapse').toggleClass('collapse');
          $('header.header').toggleClass('active');

          //Close the book now ddm
          if ($('div.book-now-dropdown').hasClass('show')) {
            $('div.book-now-dropdown > button').trigger('click');
          }
        });



        //Close the mobile menu on body click
        $(document).on('click', 'body > main', function () {
          if ($('div.hamburger').hasClass('is-active')) {
            $('div.hamburger').trigger('click');

            //Close the book now ddm
            if ($('div.book-now-dropdown').hasClass('show')) {
              $('div.book-now-dropdown > button').trigger('click');
            }
          }
        });

        //Close the slideout menu if book now ddm is clicked
        if ($('html').hasClass('touchevents') && $('div.book-now-dropdown > button').length) {
          $('div.book-now-dropdown > button').click(function(){
            if (!$(this).parent('div.book-now-dropdown').hasClass('show')) {
              //Hide slide out nav
              if ($('div.hamburger').hasClass('is-active')) {
                $('div.hamburger').trigger('click');
              }
            }
          });
        }

        //Page scroll
        $("a.page-scroll").bind("click", function (e) {
          var offset = 65,//$('header').outerHeight(),
              t = $(this),
              anchor = t.attr("href");

          if (anchor === '#next-section') {
            var p = t.closest('section');
            if (typeof p !== 'undefined') {
              anchor = p.next('section');
            }
          }

          $("html, body").stop().animate({
            scrollTop: (parseInt($(anchor).offset().top) - offset)
          }, 1500, "easeInOutExpo");
          e.preventDefault();
        });






        //Video popups
        $('.video').magnificPopup({
          type: 'iframe',
          preloader: true,
          removalDelay: 500, //delay removal by X to allow out-animation
          callbacks: {
            beforeOpen: function () {
              // just a hack that adds mfp-anim class to markup
              this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
              this.st.mainClass = this.st.el.attr('data-effect');
            }
          },
          closeOnContentClick: true
        });//end video modal


        //Image popups
        $('.magnific-gallery').each(function () {
          $(this).magnificPopup({
            delegate: 'a',
            type: 'image',
            preloader: true,
            gallery: {
              enabled: true
            },
            removalDelay: 500, //delay removal by X to allow out-animation
            callbacks: {
              beforeOpen: function () {
                // just a hack that adds mfp-anim class to markup
                this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
                this.st.mainClass = this.st.el.attr('data-effect');
              }
            },
            closeOnContentClick: true,
            midClick: true // allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source.
          });
        });//end image modal


        //HERO SLIDER
        $('.hero-slider').owlCarousel({
          items: 1,
          dots: false,
          nav: true,
          navText: ['<img src="/wp-content/themes/Aethos/dist/images/icons/chevron-left.png" alt="Arrow left"/>', '<img src="/wp-content/themes/Aethos/dist/images/icons/chevron-right.png" alt="Arrow left"/>'],
          animateOut: 'fadeOut',
          autoplay: true,
          loop: true,
          autoplaySpeed: 5000,
          autoplayTimeout:6000,
          mouseDrag: false,
          touchDrag: false,
          pullDrag: false,
          freeDrag: false,
          responsiveClass: true,
          responsive: {
            0: {
              touchDrag: true,
            },
            767: {
              touchDrag: true,
            },
            1000: {
              touchDrag: false,
            }
          }
        });//end img carousel


        //Image carousel
        $('.fw-img-carousel').owlCarousel({
          items: 1,
          dots: false,
          nav: true,
          animateOut: 'fadeOut',
          loop:true,
          navText: ['<img src="/wp-content/themes/Aethos/dist/images/icons/chevron-left.png" alt="Arrow left"/>', '<img src="/wp-content/themes/Aethos/dist/images/icons/chevron-right.png" alt="Arrow left"/>'],
          responsiveClass: true,
          responsive: {
            0: {
              nav: false,
              dots: true
            },
            767: {
              nav: true,
              dots: false
            },
            1000: {
              nav: true,
              dots: false
            }
          }
        });//end img carousel


        //Content section carousel
        $('.content-section-carousel').owlCarousel({
          items: 1,
          dots: true,
          nav: false,
          animateOut: 'fadeOut',
        });//end img carousel


        //Menu toggle for blog menu
        $('a#blog-menu-toggle').click(function(e){

          e.preventDefault();

          $('aside#blog-sidebar').toggleClass('active');

          return false;

        });


        //Blog post image carousel
        if ($('div.blog-post-img-carousel').length) {
          $('div.blog-post-img-carousel').owlCarousel({
            items: 3,
            nav: false,
            dots: true,
            responsiveClass: true,
            margin: 90,
            responsive: {
              0: {
                items: 1
              },
              767: {
                items: 2,
                margin: 60,
              },
              1024: {
                items: 2,
              },
              1366: {
                items: 3
              }
            }
          });
        }


        //Related posts carousel
        if ($('div.related-posts-carousel')) {
          $('div.related-posts-carousel').owlCarousel({
            items: 3,
            nav: false,
            dots: true,
            responsiveClass: true,
            margin: ($('body').hasClass('grid-spacing-large')) ? 15 : 0,
            responsive: {
              0: {
                items: 1
              },
              767: {
                items: 2
              },
              1000: {
                items: 3
              }
            }
          });
        }


        //Set the blog post item heading height to keep the items aligned:
        if ($('div.blog-archive-item').length) {
          set_blog_item_title_height();
        }


        //Check for window resize and run functions
        $(window).resize(function () {
          set_blog_item_title_height();
        });



        if ($('div.frontpage-links-carousel').length) {
          $('div.frontpage-links-carousel').owlCarousel({
            items: 4,
            nav: true,
            navText: ['<img src="/wp-content/themes/Aethos/dist/images/icons/chevron-left.png" alt="Arrow left"/>', '<img src="/wp-content/themes/Aethos/dist/images/icons/chevron-right.png" alt="Arrow left"/>'],
            dots: false,
            responsiveClass: true,
            loop: true,
            margin: 30,
            responsive: {
              0: {
                items: 1,
                nav: false,
                dots: true,
                margin: 0
              },
              767: {
                items: 2,
                nav: false,
                dots: true,
              },
              1024: {
                items: 3,
              },
              1366: {
                items: 4
              }/*,
              1367: {
                items: 5
              }*/
            }
          });
        }



        if ($('div.destinations-carousel').length) {
          $('div.destinations-carousel').owlCarousel({
            items: 5,
            nav: true,
            navText: ['<img src="/wp-content/themes/Aethos/dist/images/icons/chevron-left.png" alt="Arrow left"/>', '<img src="/wp-content/themes/Aethos/dist/images/icons/chevron-right.png" alt="Arrow left"/>'],
            dots: false,
            responsiveClass: true,
            loop: true,
            margin: 30,
            responsive: {
              0: {
                items: 1,
                nav: false,
                dots: true,
                margin: 0
              },
              767: {
                items: 2,
                nav: false,
                dots: true,
              },
              1024: {
                items: 3,
              },
              1440: {
                items: 4,
              },
              1900: {
                items: 5,
              }
            }
          });
        }//end if ($('div.destinations-carousel').length) {



        /**
         * Subscribe form submit
         */
        $('form#aethos-subscribe').submit(function (e) {

          e.preventDefault();

          var form_vals = $(this).serialize();
          subscribe_form_submit(form_vals, $(this));

          return false;

        });


        //Revinate form submit
        $('form#revinate_contact_api_form').submit(function(e){

          e.preventDefault();

          var form_vals = $(this).serializeArray();
          var form_vals_sorted = {};
          $.map(form_vals, function (n, i) {
            form_vals_sorted[n['name']] = n['value'];
          });

          revinate_subscribe_form_submit(form_vals_sorted, $(this));

          return false;

        });


        /**
         * Contact form submit
         */
        $('form#aethos-contact').submit(function (e) {

          e.preventDefault();

          var form_vals = $(this).serialize();
          contact_form_submit(form_vals, $(this));

          return false;

        });

        //Number input - numbers only
        $(document).on('change', 'div.aethos-form-wrap input[type="number"]', function (event) {
          this.value = this.value.replace(/[^0-9]+/g, '');
          if (this.value < 1) this.value = 0;
        });


        // Block non-numeric chars.
        $(document).on('keypress', 'div.aethos-form-wrap input[type="number"]', function (event) {
          return (((event.which > 47) && (event.which < 58)) || (event.which == 13));
        });



        //Press pinned items: Set container max width based on the number of items and margin
        position_pinned_press_items();


        //Press pinned items mouseover - detect if mouse over left or right. See element flex alignment
        $('ul.pinned-items li').on('mouseenter', function (e) {
          var $this = $(this);
          if ($this.hasClass('item-left')) {
            $('ul.pinned-items').removeClass('hover-right');
            $('ul.pinned-items').addClass('hover-left');
          } else {
            $('ul.pinned-items').removeClass('hover-left');
            $('ul.pinned-items').addClass('hover-right');
          }
        });



        //Pinned items touch device clicks
        if ($('html').hasClass('touchevents')) {

          $('ul.pinned-items li').on('mouseleave', function (e) {
            $('ul.pinned-items').removeClass('hover-right');
            $('ul.pinned-items').removeClass('hover-left');
            $('ul.pinned-items li a').removeClass('click-active');
          });



          $('ul.pinned-items li a').click(function(e){

            if ( (parseInt($(window).height()) <= 767) && (parseInt($(window).height()) > parseInt($(window).width()))) {
              return true;
            }

            var $this = $(this);

            //Unset others:
            $('ul.pinned-items li a').not(this).removeClass('click-active');

            if (!$this.hasClass('click-active')) {
              $this.addClass('click-active');
              e.preventDefault();
              return false;
            }

          });

        }//end touche vents


        //Press filters
        if ($('select#order').length && $('select#hotel').length) {

          $('select#order').select2({minimumResultsForSearch: Infinity});
          $('select#hotel').select2({minimumResultsForSearch: Infinity});

        }


        $('select#order').change(function(){

          show_press_loading();
          hide_press_no_results();

          var press_items = $('div.press-item-outer'),
              press_items_row = $('div.press-items-row'),
              do_change = false,
              this_val = $(this).val();

          //Show recommended only.
          if (this_val === 'recommended') {
            setTimeout(function () {
              $('div.press-item-outer:not(.recommended)').addClass('d-none');
            }, 1000);

            setTimeout(function () {
              hide_press_loading();
            }, 2000);
          } else {
             press_items.removeClass('d-none')
          }

          //Order old to new or vice-versa
          switch (true) {
            case (this_val === 'recommended' && !press_items_row.hasClass('new-to-old')):
            case (this_val === 'new-to-old' && !press_items_row.hasClass('new-to-old')):
              press_items_row.removeClass('old-to-new').addClass('new-to-old');
              do_change = true;
            break;

            case (this_val === 'old-to-new' && !press_items_row.hasClass('old-to-new')):
              press_items_row.removeClass('new-to-old').addClass('old-to-new');
              do_change = true;
            break
          }

          setTimeout(function () {
            if (do_change) {
              change_press_items_order(press_items, this_val);
            }

            press_check_for_no_results();
          }, 1000);



          setTimeout(function () {
            hide_press_loading();
          }, 2000);


        });


        $('select#hotel').change(function(){

          show_press_loading();
          hide_press_no_results();

          var val = $(this).val();
          if (val === 'all') {
            setTimeout(function () {
              $('div.press-item-outer').removeClass('hotel-d-none');
            }, 1000);

            setTimeout(function () {
              hide_press_loading();
            }, 2000);

            return;
          }

          setTimeout(function () {
            $('div.press-item-outer.'+val+'').removeClass('hotel-d-none');
            $('div.press-item-outer:not(.'+val+')').addClass('hotel-d-none');

            press_check_for_no_results();
          }, 1000);




          setTimeout(function () {
            hide_press_loading();
          }, 2000);

        });


        //If the hotel select has a trigger-change
        if ($('select#hotel').hasClass('select-trigger')) {
          $('select#hotel').trigger('change');
        }

        //Add / remove overflow hidden from body when booking ddm is closed.
        $('#booking-form-nav-dropdown').on('show.bs.dropdown', function(){
          if (parseInt($(window).width()) <= 767) {
            $('body').addClass('ofh');
          }
        });

        $('#booking-form-nav-dropdown').on('hide.bs.dropdown', function(){
          $('body').removeClass('ofh');
        });


        //Set the property page sticky nav
        // set_nav_sticky();

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
